import {connect} from "react-redux";
import {Dispatch} from "redux";
import {authRequestsSlice, State} from "store/";
import userFriendlyErrorMessages from "utils/userFriendlyErrorMessages";

interface StateProps {
    /** The error message generated by the Change Password process. */
    error?: string;

    /** The loading state while the Change Password process is running. */
    loading?: boolean;
}

interface DispatchProps {
    /** Handler for submitting the form. */
    onSubmit: () => void;
}

export interface ConnectedProps extends StateProps, DispatchProps {}

const errorMessageMap = (error: string) => {
    switch (error) {
        case "Invalid login":
            return "Wrong password - try again";
        default:
            return userFriendlyErrorMessages(error);
    }
};

const mapStateToProps = (state: State): StateProps => ({
    error: errorMessageMap(authRequestsSlice.deleteUserAccount.selectors.selectErrorMessage(state)),
    loading: authRequestsSlice.deleteUserAccount.selectors.selectLoading(state)
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    onSubmit: () => dispatch(authRequestsSlice.deleteUserAccount.actions.request())
});

export default connect(mapStateToProps, mapDispatchToProps);
