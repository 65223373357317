import {connect} from "react-redux";
import {Dispatch} from "redux";
import {authRequestsSlice, userSlice, State} from "store/";
import userFriendlyErrorMessages from "utils/userFriendlyErrorMessages";

interface StateProps {
    /** The user's current email address. */
    currentEmail: string;

    /** The error message generated by the Change Password process. */
    error?: string;

    /** The loading state while the Change Password process is running. */
    loading?: boolean;
}

interface DispatchProps {
    /** Handler for submitting the form. */
    onSubmit: (newEmail: string) => void;
}

export interface ConnectedProps extends StateProps, DispatchProps {}

const errorMessageMap = (error: string) => {
    switch (error) {
        default:
            return userFriendlyErrorMessages(error);
    }
};

const mapStateToProps = (state: State): StateProps => ({
    currentEmail: userSlice.selectors.selectUserEmail(state),
    error: errorMessageMap(authRequestsSlice.changeEmail.selectors.selectErrorMessage(state)),
    loading: authRequestsSlice.changeEmail.selectors.selectLoading(state)
});

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
    onSubmit: (newEmail) => dispatch(authRequestsSlice.changeEmail.actions.request({newEmail}))
});

export default connect(mapStateToProps, mapDispatchToProps);
